export const defaultEditorContent = {
  type: "doc",
  content: [
    {
      type: "heading",
      attrs: { level: 1 },
      content: [{ type: "text", text: "Introducing Auto EE Design Tools" }],
    },
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          text: "Nebula-AI Auto EE Design Tools is a tool that allows you to integrate AI/LLM into the development process to enhance efficiency and teamwork."
        }
      ]
    },
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          text: "Nebula-AI 的 Auto EE Design Tools 通过融合 AI/LLM到整个开发过程中，旨在提高工作效率和团队协作。"
        }
      ]
    },
    {
      type: "heading",
      attrs: { level: 2 },
      content: [{ type: "text", text: "Features" }],
    },
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          text: "Select the input content and choose 'Ask AI'"
        }
      ]
    },
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          text: "选中输入的内容，选择'Ask AI'"
        }
      ]
    },
    {
      type: "bulletList",
      attrs: { tight: true },
      content: [
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              content: [{ type: "text", text: "Make shorter 精简" }]
            }
          ]
        },
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              content: [{ type: "text", text: "Make longer 扩写" }]
            }
          ]
        },
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              content: [{ type: "text", text: "Refine usecase 完善用例" }]
            }
          ]
        },
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              content: [{ type: "text", text: "Generate testcase 生成测试用例" }]
            }
          ]
        },
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              content: [{ type: "text", text: "Generate requirement 生成需求" }]
            }
          ]
        },
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              content: [{ type: "text", text: "Generate test script 生成测试脚本" }]
            }
          ]
        },
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              content: [{ type: "text", text: "FMEA analysis FMEA 分析" }]
            }
          ]
        }
      ]
    },
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          text: "Note: The tool's initial release includes only defined features of function definition and quality analysis.\n"
        },
        {
          type: "text",
          text: "注：工具初版仅实现了功能定义及质量分析部分特性。"
        }
      ]
    },
    {
      type: "heading",
      attrs: { level: 2 },
      content: [{ type: "text", text: "Next Steps" }],
    },
    {
      type: "bulletList",
      attrs: { tight: true },
      content: [
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              content: [{ type: "text", text: "To improve basic editing functions 完善基本编辑功能" }]
            }
          ]
        },
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              content: [{ type: "text", text: "To implement AI features in the roadmap 实现路线图中的AI特性" }]
            }
          ]
        },
      ]
    },
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          text: "Welcome to provide feedback on the expected features (Type / to activate 'SendFeedback')\n"
        },
        {
          type: "text",
          text: "欢迎反馈期待的功能（键入/ 激活'SendFeedback'）"
        }
      ]
    },
    {
      type: "heading",
      attrs: { level: 2 },
      content: [{ type: "text", text: "Playground" }],
    },
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          text: "Usecase: Automatically close vehicle windows when it rains.\n"
        },
        {
          type: "text",
          text: "用例：下雨自动关闭车窗"
        }
      ]
    }
  ]
};
